@layer tailwind-base {
  :root {
    --input-border: theme('colors.gray.300');
    --input-border-hover: theme('colors.gray.400');
    --uploader-droparea-border: theme('colors.neutral.200');
    --uploader-droparea-bg: theme('colors.gray.50');
    --button-primary-bg: theme('colors.primary.600');

    --surface-sheet: theme('colors.gray.100');
    --surface-page: theme('colors.gray.50');
    --surface-sidebar: theme('colors.gray.200');

    --p-form-field-placeholder-color: theme('colors.gray.300');
    --p-inputtext-focus-border-color: theme('colors.primary.300');
  }

  label[for]:not(:empty) {
    @apply flex items-center text-base leading-5 text-gray-500;

    em {
      @apply text-xxxs not-italic text-gray-500;
    }
  }
  ui-label:has(+ input:not([disabled])) > label[for]:not([for='']) {
    @apply cursor-pointer;
  }

  ui-dialog label {
    @apply font-medium text-gray-500;

    em {
      @apply text-gray-300;
    }
  }

  dl {
    dt {
      @apply pt-2 font-normal not-italic text-neutral-500 first-of-type:pt-0;
    }
    dd {
      @apply pt-2 text-neutral-700 first-of-type:pt-0;
    }
  }

  time {
    @apply text-gray-800;
    span {
      @apply block text-gray-500;
    }
  }
}
