// FR >  docasne styluju na stejnem miste, REFACTORING
dc-spotlight,
dc-blacklist-searchbox,
dc-page-daktela-archive {
  display: block;
  text-align: center;

  .c-row__col {
    position: relative;
  }

  .c-input-group__field {
    > .dc-spotlight__search-input {
      border-width: 2px;
      border-style: solid;
      border-color: #eee;
      border-radius: $radius--md;
      padding-left: 2.75rem;
      padding-right: 2.75rem;

      box-shadow: 0 0 0 0 rgba(82, 168, 236, 0);
      transition: all $speed ease-in-out;

      &::placeholder {
        color: $grey-3;
      }

      &:focus {
        border-color: $focus;
        box-shadow: 0 0 15px 5px rgba(82, 168, 236, 0.13);
      }
    }
  }

  .c-input-group__field {
    position: relative;
    font-size: 1.25rem;

    .fa-search,
    .fa-close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      color: $grey-3;
    }

    .fa-close {
      left: auto;
      width: 14px;
      height: 14px;
      color: white;
    }
  }
}

.dc-spotlight {
  &__reset {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    width: 18px;
    height: 18px;
    padding: 0;

    color: $white;

    background: darken($milk, 30%);
    border-radius: 50%;

    transition: background 100ms ease-in-out;

    &:hover {
      background: $danger;

      .fa-close {
        color: $white;
      }
    }
  }

  &__search-field {
    margin: auto;
    margin-bottom: 0;
    max-width: 550px;

    .c-row__col {
      padding-left: _space(0.25);
      padding-right: _space(0.25);
      margin-bottom: _space(0.5);
    }
  }

  &__info-line {
    display: block;
    margin-bottom: _space(0.5);
    text-align: center;
    color: $antracit;

    ul {
      max-width: 325px;
      margin-top: _space(0.25);
      @include push--auto();
      text-align: left;
    }
  }
}

.item--address {
  flex-basis: 40% !important;
  text-align: left;
}

//
//  Results
//
.dc-blacklist-results,
dc-spotlight-results-partners,
dc-spotlight-results-objects {
  display: block;
  width: $breakpoint__lg;
  @include push--auto();

  margin-bottom: _space(0.5);
  color: $text;
  text-align: left;

  .c-row {
    align-items: center;
    display: flex;
    &__col {
      flex-basis: 15%;
    }
  }

  .dc-spotlight-results__item-partner {
  }
  .dc-spotlight-results__subitem-policy {
    > * {
      flex-basis: 14%;
    }
  }
  .dc-spotlight-results__subitem-loss-event,
  .dc-spotlight-results__subitem-claim,
  .dc-spotlight-results__subitem-subclaim {
    > * {
      flex-basis: 20%;
    }
  }

  .dc-spotlight-results__subitem-policy,
  .dc-spotlight-results__subitem-loss-event,
  .dc-spotlight-results__subitem-claim,
  .dc-spotlight-results__subitem-subclaim {
    text-align: left;
    display: flex;
    justify-content: space-between;
    > * {
      .c-row {
        all: unset;
      }
    }
    .POLICY_NUMBER span,
    .CLAIM_NUMBER span,
    .LOSS_EVENT_NUMBER span,
    .SUBCLAIM_NUMBER span,
    .SUBCLAIM_NUMBER_EXT span {
      padding: 2px 6px;
    }
  }

  .dc-spotlight-results__subitem-subclaim {
    .SHORT_PRODUCT {
      // flex-basis: 14rem;
    }
  }

  .SHORT_PRODUCT {
    position: relative;
    .noDamagedPersonInfo {
      position: absolute;
      left: -21px;
      &::before {
        color: orange;
        font-family: $ff--icons--fontAwesome;
        content: '\f06a'; // fa-exclamation-circle
      }
    }
  }

  .SUBCLAIM_NUMBER {
    display: flex;
    width: 100%;
  }

  .dc-spotlight-results__item-scope {
    background-color: darken($concrete, 5%);
    color: $info;
    font-weight: bold;
    padding: 5px;
  }

  .dc-spotlight-results__item {
    // highlighting search results
    &.PERSONAL_IN .PERSONAL_IN,
    &.PHONE .PHONE,
    &.EMAIL .EMAIL,
    &.LAST_NAME .LAST_NAME,
    &.FIRST_NAME .FIRST_NAME,
    &.BIRTH_DATE .BIRTH_DATE,
    &.PERMANENT_ADDRESS .PERMANENT_ADDRESS,
    &.COMPANY_IN .COMPANY_IN,
    &.COMPANY_NAME .COMPANY_NAME,
    &.CONTACT_ADDRESS .CONTACT_ADDRESS,
    &.VEHICLE_VIN .VEHICLE_VIN,
    &.REGISTRATION_PLATE .REGISTRATION_PLATE,
    &.DESCRIPTION .DESCRIPTION,
    // highlight policies
    .POLICY_ALTNUM .POLICY_ALTNUM,
    .POLICY_NUMBER .POLICY_NUMBER,
    .POLICY_VSNUM .POLICY_VSNUM,
    .VEHICLE_VIN .VEHICLE_VIN,
    .VTP_IN .VTP_IN,
    .REGISTRATION_PLATE .REGISTRATION_PLATE,
    .PET_CHIP .PET_CHIP,
    .SIMPLE_VEHICLE_REGISTRATION_PLATE .REGISTRATION_PLATE,
    .SIMPLE_VEHICLE_VIN .REGISTRATION_PLATE,
    .SIMPLE_VEHICLE_VIN .VEHICLE_VIN,
    // highlight loss events
    .LOSS_EVENT_NUMBER .LOSS_EVENT_NUMBER,
    // highlight claims
    .CLAIM_NUMBER .CLAIM_NUMBER,
    // highlight subclaims
    .SUBCLAIM_NUMBER .SUBCLAIM_NUMBER,
    .SUBCLAIM_NUMBER_EXT .SUBCLAIM_NUMBER_EXT,
    .SUBCLAIM_ROLE .SUBCLAIM_ROLE,
    span .POLICY_VSNUM {
      span {
        background-color: $highlight;
        padding: 2px 0px;
        letter-spacing: 0.5px;
        color: $black;
        border-radius: $radius--md;

        a {
          color: darken($action, 15%);
        }
      }
    }
  }

  // pro vypis napr. v souhrnu v poslednim kroku formulare
  &.--flat {
    color: $temp__sedivka-6;
    padding: 0;
    box-shadow: none;
  }
}

.dc-blacklist-results {
  .dc-spotlight-results__item {
    .spotlight__partner-name {
      display: block;

      &:after {
        left: 10px;
      }
    }
  }
}

.spotlight-toggle-pic {
  position: absolute;
  left: -50px;
  top: -1px;
  &--green {
    width: 42px;
  }
  &--gray {
    width: 38px;
  }
  &:hover {
    cursor: pointer;
  }
}

//================================================= cleanup

.dc-spotlight-results {
  margin-bottom: _space(1);
  @include _padding(0.5);
  background: $white;
  border-radius: $radius--lg;
  box-shadow: 0 8px 16px 0 rgba($black, 0.1);
}

.LOSS_EVENT_ROLE,
.CLAIM_ROLE .CLAIM_NUMBER,
.SHORT_PRODUCT,
.POLICY_NUMBER,
.CURRENT_STATUS,
.LOSS_EVENT_TYPE,
.LOSS_EVENT_NUMBER,
.LOSS_EVENT_INSURED,
.SUBCLAIM_NUMBERS,
.SUBCLAIM_CLAIMER,
.DESCRIPTION {
  // empty
}

//
//  Results — Item
//
.dc-spotlight-results__item {
  @include _padding(20px, 0.5, 20px, 0.25);
  border-radius: $radius--md;
  background: $concrete;
  transition: all $speed ease;

  &:not(:last-child) {
    @include _margin-bottom(0.5);
  }

  &:hover {
    background: $white;
    border-bottom-color: $white;

    .dc-spotlight-results__subitem span {
      background: $white;
    }

    .dc-spotlight-results__subitem button span {
      background: inherit;
    }
  }

  .e-mail {
    display: inline-block;
    max-width: 180px;
    @include truncate();
    a {
      //word-wrap: break-word;
    }
  }
}

// only one search result
.dc-spotlight-results__item:only-child,
.dc-spotlight-results__subitem-heading > span {
  background: $white;
  padding-top: 20px;
}

.spotlight__partner-name {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  @include _padding-left(0.25);

  .dc-icon {
    @include _padding(0, 0.125, 0, 0);
  }

  // left line
  &:after {
    @include pseudo();
    top: -6px;
    left: -20px;

    width: 6px;
    height: 30px;

    background: $brand-alt;
    border: 2px solid $white;
    border-radius: $radius--md;
  }
}

//
//  Results — Sub-Item
//
.dc-spotlight-results__subitem {
  > * {
    text-align: left;
  }
  display: block;
  padding-left: 27px;

  &-heading {
    height: 42px;
    position: relative;

    text-transform: lowercase;
    color: $antracit;
    font-size: 12px;

    > span {
      display: inline-block;
      position: absolute;
      left: 18px;
      bottom: 0;
      z-index: 2;

      background: $concrete;
      @include _padding(0.25);

      transition: background-color $speed ease-in-out;
    }

    // line
    &:after {
      @include pseudo();
      left: 0;
      bottom: 15px;
      z-index: 1;

      width: 100%;
      height: 1px;
      background: $grey-3;
    }
  }

  .c-row.dc-spotlight-results__subitem-loss-event,
  .c-row.dc-spotlight-results__subitem-claim,
  .c-row.dc-spotlight-results__subitem-subclaim {
    .c-row__col {
      align-self: stretch;
    }
  }

  .c-row {
    // underline row on :hover
    .c-row__col {
      min-height: 23px;
      border-bottom: 1px dotted transparent;
    }
  }

  .address-flex {
    flex-basis: 14% !important;
  }
}

.address-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  &--wrapping {
    padding-right: 30px;
    white-space: pre-wrap;
  }
}
