.p-select-overlay {
  .p-select-header {
    @apply relative rounded-t-lg bg-gray-100 px-3.5 py-2;

    .p-iconfield {
      .p-select-filter {
        @apply w-full focus:ring focus:ring-primary-600/20;
      }
      .p-inputicon {
        @apply -mt-[10px];
      }
    }
  }

  .p-select-list {
    @apply p-2;

    .p-select-option {
      @apply cursor-pointer rounded-md px-3 py-2;

      span {
        @apply whitespace-normal;
      }

      &:not(.p-highlight):not(.p-disabled) {
        @apply hover:bg-blue-gray-50;
      }

      &.p-select-option-selected {
        &.p-focus {
          --p-select-option-selected-focus-background: var(--p-select-option-selected-background);
        }
      }
    }
  }
}
