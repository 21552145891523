p-badge {
  &.p-badge {
    @apply inline-flex items-center rounded-full;
  }

  &.p-badge-info {
    @apply text-white;
  }
}

p-chip {
  .p-chip {
    @apply bg-surface-200 px-2 py-1 text-xxs/4 font-normal text-gray-600;
  }
}
