p-multiselect.p-multiselect {
  .p-multiselect-label {
    @apply pl-3.5;
  }

  .p-icon-wrapper {
    @apply flex;
  }

  .p-multiselect-dropdown {
    @apply relative;

    &:before {
      @apply absolute inset-0 my-2 h-auto w-px bg-gray-200 content-empty;
    }
  }

  .p-multiselect-dropdown-icon {
    @apply inline-flex;
  }

  .p-multiselect-clear-icon {
    @apply mr-2;
  }

  &:not(.p-disabled) {
    &.p-focus {
      @apply border-primary-300 ring ring-primary-600/20;
    }
  }
  &.p-disabled {
    @apply cursor-not-allowed bg-transparent opacity-50;
  }

  &.p-multiselect-display-chip {
    .p-multiselect-label.p-placeholder {
      @apply p-2 pl-3 text-base leading-6;
    }

    .p-multiselect-label {
      @apply p-[0.45rem];

      .p-multiselect-token {
        @apply mr-1;
      }

      .p-multiselect-token-label {
        @apply text-base leading-5;
      }
    }
    .p-multiselect-clear-icon {
      @apply mr-4;
    }
  }
}

.p-multiselect-overlay {
  @apply my-[2px] rounded-lg;

  .p-multiselect-header {
    @apply relative rounded-t-lg bg-gray-100 px-3.5 py-2;

    .p-multiselect-filter {
      @apply focus:ring focus:ring-primary-600/20;
    }
  }

  .p-inputicon {
    @apply m-0 -translate-y-1/2;
  }

  .p-multiselect-filter-icon {
    @apply inline-flex;
  }
}

p-multiselect-item {
  li.p-multiselect-option {
    @apply flex items-start;

    p-checkbox {
      @apply inline-flex self-center;
    }

    span {
      @apply whitespace-pre-wrap;
    }
  }
}

.p-inputwrapper-filled .p-multiselect {
  &.p-multiselect-chip {
    .p-multiselect-label {
      @apply p-2;
    }
  }
}

.p-column-filter-overlay {
  p-multiselect {
    .p-multiselect-clear-icon {
      @apply right-12 -mt-2.5 mr-1 inline-flex;
    }
  }
}
