.p-menu,
.ui-menu.p-menu {
  --p-menu-border-color: transparent;
  @apply rounded-lg border-0 p-0;

  .p-hidden {
    @apply hidden;
  }

  .p-menu-list {
    @apply gap-0 rounded-md  p-2;

    .p-menu-item {
      @apply whitespace-nowrap;

      &.p-menu-item-destructive {
        .p-menu-item-link {
          .p-menu-item-icon,
          .p-menu-item-text {
            @apply text-red-600;
          }
        }
      }
    }

    .p-menuitem-link {
      @apply hover:bg-neutral-200 hover:no-underline;
    }

    .p-menuitem-link-active {
      @apply border-0 bg-neutral-200;
    }

    .p-menu-separator {
      @apply -mx-2 my-2 border-t-gray-200;
    }

    .p-menuitem-link:not(.p-highlight):not(.p-disabled) {
      @apply rounded-md bg-transparent p-3 text-gray-700 hover:bg-gray-50;
    }

    .p-menuitem-destructive .p-menuitem-link:not(.p-highlight):not(.p-disabled) {
      @apply text-red-500 hover:bg-red-100/50;

      svg {
        @apply stroke-red-600;
      }
    }

    .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menu-item-content {
      @apply hover:bg-transparent;
    }
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menu-item-content {
      @apply bg-transparent;
    }
  }

  &.ui-menu-condensed {
    .p-menuitem-link:not(.p-highlight):not(.p-disabled) {
      @apply flex p-1;
    }
  }
}
