.p-datatable {
  .p-datatable-header {
    @apply rounded-t-xl;
  }
  .p-datatable-table-container {
    @apply rounded-b-xl;
  }

  .p-datatable-thead > tr > th {
    @apply border-b-gray-200 bg-gray-50 text-sm/4 font-medium leading-5 text-gray-500;

    &.p-datatable-column-sorted {
      @apply bg-gray-50 text-gray-700;
    }

    p-columnfilter,
    p-columnfilter p-button {
      @apply flex;

      span {
        @apply inline-flex;
      }

      .p-fluid {
        @apply w-full;
      }
    }
  }

  .p-datatable-tbody > tr > td {
    @apply border-b-gray-200 leading-5;

    &.p-disabled {
      @apply pointer-events-none cursor-default text-gray-300 grayscale;
    }

    &.actions {
      @apply text-right;
    }

    a[target='_blank'] {
      @apply inline-flex items-center gap-1;

      &:after {
        @apply content-empty;
      }
      i,
      &:after {
        @apply inline-flex h-[18px] w-[17px] bg-current;
        mask: url('/assets/icons/Outline/External Link.svg');
        mask-size: contain;
      }
    }
  }

  .p-datatable-first-col-fixed {
    .p-datatable-thead > tr > th:first-of-type,
    .p-datatable-tbody > tr > td:first-of-type {
      @apply p-0 text-center;
    }

    .p-datatable-thead > tr > th:first-of-type {
      @apply pt-1;
    }

    .p-datatable-tbody > tr > td[rowspan] {
      @apply border-solid;
    }
    .p-datatable-tbody > tr > td[colspan] {
      @apply px-3 pb-4 pt-0 text-left;
    }
  }

  .p-datatable-loading-overlay {
    @apply bg-white/70;
  }

  .p-datatable-sortable-column {
    &:not(.p-datatable-column-sorted) {
      @apply hover:bg-gray-200;
    }

    &.p-datatable-column-sorted {
      @apply text-gray-700 shadow-none hover:text-inherit;

      ui-sort-icon {
        @apply opacity-100;

        svg {
          @apply fill-gray-700;
        }
      }
    }

    ui-sort-icon {
      @apply inline-flex opacity-0;
    }

    &:hover {
      ui-sort-icon {
        @apply opacity-100;
      }
    }
  }

  &.p-datatable-striped.p-datatable-gridlines {
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      @apply p-2;
    }

    .p-datatable-tbody > tr:nth-child(odd) {
      @apply bg-slate-50;
    }
  }

  .p-datatable-tfoot {
    @apply border-0 border-t-px border-solid border-gray-200 p-2;
  }

  .p-datatable-mask.p-overlay-mask {
    @apply absolute rounded-xl bg-white/75;
  }
}

.p-datatable-filter-overlay-popover {
  .p-datatable-filter-buttonbar:empty,
  .p-datatable-filter-rule div:last-child:empty {
    @apply hidden;
  }
}
