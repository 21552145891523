.p-inputnumber .p-inputnumber-input,
.p-inputtext,
.p-inputtext.p-component,
.p-inputtext.p-filled,
.p-inputtext.ng-touched,
.p-inputtext.p-inputmask,
textarea.p-textarea {
  @apply m-0 rounded-lg border-gray-300 text-gray-700;

  &:not([disabled]):hover {
    @apply border-[color:var(--input-border-hover)];
  }
  &:disabled,
  &.p-disabled {
    @apply cursor-not-allowed bg-transparent opacity-50;
  }

  &:enabled:focus {
    @apply border-primary-300 ring ring-primary-600/20;
  }

  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    @apply relative rounded-lg border-red-300 bg-input-error bg-[length:22px] bg-xy-offset bg-no-repeat ring ring-red-100;

    &:enabled:focus,
    &:enabled:focus .p-inputnumber-input {
      @apply border-red-300 ring ring-red-100;
    }

    + div,
    + small {
      @apply mt-2 text-base font-medium text-red-700;
    }
  }
}

p-inputnumber,
p-inputnumber.p-inputnumber,
.p-inputnumber {
  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    .p-inputnumber-input {
      @apply relative rounded-lg border-red-300 bg-input-error bg-[length:22px] bg-xy-offset bg-no-repeat ring ring-red-100;
    }

    + span {
      @apply hidden;
    }
  }

  .p-inputnumber-button-group {
    @apply gap-y-px;

    .p-inputnumber-button {
      @apply rounded-none bg-gray-200 hover:bg-primary-200;

      &.p-inputnumber-increment-button {
        @apply rounded-tr-lg;
      }
      &.p-inputnumber-decrement-button {
        @apply rounded-br-lg;
      }
    }
  }
}

p-inputmask {
  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    input.p-inputtext {
      @apply relative border-red-300 bg-input-error bg-[length:22px] bg-right-offset bg-no-repeat ring-4 ring-red-100;

      &:enabled:focus {
        @apply border-red-300 ring ring-red-100;
      }
    }
  }

  &.ng-touched.ng-invalid + div,
  &.ng-dirty.ng-invalid + div {
    @apply mt-2 text-base font-medium text-red-700;
  }
}

.p-inputtext:has(+ ui-validation-warning):not(:focus),
.smartform-company-name:has(+ ui-validation-warning),
.smartform-address-street-and-number:has(+ ui-validation-warning),
.smartform-email:has(+ ui-validation-warning) {
  @apply border-amber-500 bg-input-warning bg-[length:24px] bg-[center_right_5px] bg-no-repeat ring-4 ring-orange-200/50;
}
