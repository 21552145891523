// FR > refuck

//
//  FILTER
//
// TS: Filtry, pouze provozni nastrel

.ui-filter {
  padding-bottom: _space(0.25);

  .filter-header {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    padding-bottom: _space(0.25);
    &::after {
      @include clearfix();
    }
    .link--clear {
      float: right;
    }
  }
  .filter-wrapper {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    padding-bottom: _space(0.25);
    display: block;
    &::after {
      @include clearfix();
    }
    .filter-items-list {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      list-style: none;
      .filter-item {
        display: none;
        .item-link {
          margin: 0;
          padding: 0 2px;
          display: inline-block;
          position: relative;
          color: inherit;
          background-color: transparent;
          border-radius: $radius--sm;
          a {
            color: inherit;
            text-decoration: none;
          }
          .icon {
            width: 20px;
            display: block;
            float: left;
            position: relative;
            top: -4px;
            margin-bottom: -10px;
            font-size: 16px;
            line-height: inherit;
            text-align: center;
          }
          .text {
            padding: 0 5px 0 0;
            display: block;
            float: left;
          }
          .ui-filter__item-close {
            width: 20px;
            text-align: center;
            display: none;
            float: right;
            font-style: normal;
          }
          &:hover {
            .text {
              text-decoration: underline;
            }
          }
        }
        &.--active {
          display: block;
          .item-link {
            color: white;
            background-color: $warning;
            .icon {
              display: none;
            }
            .text {
              padding: 0 0 0 5px;
            }
            .ui-filter__item-close {
              display: block;
            }
          }
        }
      }
    }
  }
  // TS: možná bych zvolil jen open
  &.--opened {
    .filter-wrapper {
      .filter-items-list {
        .filter-item {
          display: block;
          .item-link {
          }
          &.--active {
          }
        }
      }
    }
  }

  // origin styles below --->
  // Hide/Show Filters button
  &__switch {
    cursor: pointer;
    &.--active {
      color: $warning;
    }
    &.--opened {
      cursor: default;
      color: $temp__sedivka-3 !important;
    }
  }
  // Filter item
  &__item {
    display: none;
    &-close {
      display: none;
    }

    // FR > remove @extend?
    &.--active {
      @extend .tag;
      @extend .--removable;
      pointer-events: all;
      i {
        @extend .tag__remove;
      }
    }
    &.--active &-close {
      display: inline;
    }
  }
  &.--opened &__item,
  &__item.--active {
    display: block;
  }
}

// .nc-filter .p-tabview {
//   .p-tabview-nav li.p-highlight .p-tabview-nav-link {
//     background-color: aliceblue;
//   }

//   .p-tabview-nav li {
//     width: 50%;

//     .p-tabview-nav-link:not(.p-disabled):focus {
//       box-shadow: none;
//     }
//   }
// }
