.ui-calendar-datepicker.p-datepicker-panel {
  @apply rounded-lg border-none p-0 shadow-panel;

  .p-datepicker-year-view,
  .p-datepicker-month-view {
    @apply max-w-lg;
  }

  .ui-switch label {
    @apply whitespace-nowrap;
  }

  /* shortcuts */
  &:has(p-footer:not(:empty)) {
    @apply grid grid-cols-[minmax(10rem,_auto)_1fr];

    .p-datepicker-time-picker {
      @apply border-0 border-l border-solid border-l-gray-100 pt-0;

      span {
        @apply text-base;
      }
    }

    .time-toggle {
      @apply border-0 border-t border-solid border-t-gray-100;
    }

    .preset-footer {
      @apply bg-gray-50;
    }
  }

  /* month and date picker */
  &:has(.p-datepicker-year-view),
  &:has(.p-datepicker-month-view) {
    @apply flex flex-col;
  }

  /* time picker */
  &:has(.p-datepicker-time-picker):has(p-footer:not(:empty)) {
    @apply grid grid-cols-[minmax(10rem,_auto)_1fr_10rem];

    .time-footer {
      @apply border-0 border-l border-solid border-l-gray-100;
    }
  }
  &:has(.p-datepicker-time-picker):not(:has(p-footer)) {
    @apply grid-cols-[minmax(10rem,_auto)_10rem];

    .time-toggle {
      @apply border-0 border-t border-solid border-t-gray-100;
    }
    .p-datepicker-time-picker {
      @apply border-0 border-l border-solid border-l-gray-100;
    }
  }
  &:has(.p-datepicker-year-view):has(.time-toggle) .time-toggle,
  &:has(.p-datepicker-month-view):has(.time-toggle) .time-toggle {
    @apply hidden;
  }

  .p-datepicker-header {
    @apply rounded-t-lg border-b-0 p-2;

    .p-datepicker-title {
      .p-datepicker-select-month,
      .p-datepicker-select-year {
        @apply bg-transparent text-lg text-gray-700;
      }

      .p-datepicker-select-year {
        @apply text-primary-300;
      }

      .p-datepicker-decade {
        @apply font-semibold;
      }
    }

    .p-datepicker-prev-button,
    .p-datepicker-next-button {
      @apply enabled:hover:bg-gray-100;
    }
  }

  .p-datepicker-day-view {
    @apply order-last;

    thead > tr {
      @apply border-b border-gray-100;
    }

    tr th > span {
      @apply text-base font-bold text-gray-500;
    }

    td {
      @apply p-[1px];
      span {
        @apply h-[2.625rem] w-[2.625rem];
      }
    }

    td > .p-datepicker-day-selected,
    td.p-datepicker-today > .p-datepicker-day-selected {
      @apply bg-primary-50 font-medium text-primary-700;
    }

    .p-datepicker-today > .p-datepicker-day {
      @apply relative bg-transparent after:absolute after:bottom-[3px] after:h-[4px] after:w-[10px] after:rounded-full after:bg-gray-300 after:content-empty;

      &.p-datepicker-day-selected {
        @apply after:bg-primary-200;
      }
    }

    td span:not(.p-datepicker-day-selected):not(.p-disabled):hover {
      @apply hover:border-gray-300 hover:bg-transparent;
    }
  }

  .p-datepicker-month-view,
  .p-datepicker-year-view {
    @apply p-2 pt-0;
  }

  .p-datepicker-year-view {
    .p-datepicker-year-selected {
      @apply bg-primary-50 font-medium text-primary-700;
    }
  }

  .p-timepicker {
    button {
      @apply bg-gray-50 enabled:hover:bg-gray-100;
    }
  }

  p-footer {
    @apply order-first flex flex-col gap-2 rounded-l-lg bg-gray-50 p-4 empty:hidden;

    .p-datepicker-buttonbar,
    .p-datepicker-buttonbar > div {
      @apply flex w-full flex-col items-start gap-2 border-t-0;
    }

    p-button,
    .p-button {
      @apply w-full whitespace-nowrap;
      span {
        @apply text-left;
      }
    }
  }

  .p-datepicker-month-view,
  .p-datepicker-year-view {
    @apply col-span-full;
  }

  .p-datepicker-month-view ~ p-footer,
  .p-datepicker-year-view ~ p-footer {
    @apply hidden;
  }
}
