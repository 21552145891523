.p-popover {
  &:before,
  &:after {
    @apply hidden;
  }
}

.p-overlay,
.p-overlay.p-component {
  @apply w-min;

  .p-select-overlay,
  .p-multiselect-overlay {
    @apply mt-[2px] rounded-lg border-none shadow-dropdown;
  }
}

.ui-menu.p-menu-overlay {
  width: unset;
  @apply mt-[2px] min-w-[12rem] rounded-lg border-none shadow-menu;
}

.nc-header-menu {
  &.p-popover {
    @apply mt-5 rounded-t-none border-0;

    .p-popover-content {
      @apply p-0;
    }
  }
}
