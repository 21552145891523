html {
  @apply scroll-smooth;
}

*,
*::before,
*::after {
  @apply box-border;
}

html,
body {
  @apply h-full font-stack;
}

body {
  @apply m-0 flex max-h-full max-w-full bg-neutral-50;
}

dc-app,
fb-app {
  @apply relative min-h-screen grow;

  &:has(dc-page-dashboard) ui-breadcrumbs,
  ui-breadcrumbs:has(+ ui-loader) {
    @apply hidden;
  }
}

.l-page,
router-outlet + * {
  @apply z-5 flex-auto;
}

.l-page {
  @apply mb-8 mt-4;
}
