p-checkbox {
  @apply inline-flex;

  .p-checkbox-label {
    @apply cursor-pointer;
  }

  .p-checkbox {
    @apply h-auto w-auto;

    &.p-disabled,
    &.p-checkbox-label.p-disabled {
      .p-checkbox-box {
        @apply opacity-50;
      }
    }

    &.p-checkbox-checked.p-disabled {
      .p-checkbox-box {
        @apply border-none bg-primary-400 opacity-50;
      }
    }

    .p-checkbox-box {
      @apply h-[16px] w-[16px];

      .p-checkbox-icon {
        @apply scale-75;
      }
    }
  }
}

p-checkbox + label {
  @apply mb-0 inline-flex;
}
