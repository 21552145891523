.special {
  .dc-overview-box {
    &__heading {
      ui-button-legacy {
        border-radius: 14px;
        font-size: 14px;
        padding: 1px 10px 2px 10px;
        background: $action;
        line-height: 1px;
        button {
          color: $white;
        }
        .badge {
          all: unset;
          margin-left: 10px;
        }
      }
    }
  }
}

.dc-overview-box {
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1.25rem;

  border-bottom: 1px solid rgba($black, 0.1);

  &__heading {
    @apply flex min-h-8 items-center gap-2;

    ui-angle-legacy {
      @apply mr-0 inline-flex items-center justify-center;
    }

    &.vehicle {
      margin-left: 20px;
      ui-angle-legacy {
        background: $grey-6;
        width: 15px;
        height: 15px;
        .dc-icon {
          top: -3px;
          font-size: 11px;
        }
      }
      ui-button-legacy {
        top: -1px;
        button {
          text-decoration: underline;
          text-transform: unset;
          color: $black;
        }
      }
    }
    .dc-btn:focus {
      outline: 0.5px solid $grey-3;
    }
  }

  &__section-toggle {
    @apply inline-flex;

    .dc-btn {
      padding: 0;
      border-radius: 0;
      font-size: 1.25rem;
      line-height: $lh--small;
    }
  }

  &__actions {
    @apply ml-auto;

    .dc-btn {
      margin-left: _space(0.25);
    }
  }
  &__body {
    width: 100%;
    margin-top: _space(0.75);

    .c-row__col {
      display: flex;
    }
  }

  .td--policyNumber {
    .dc-icon {
      margin-right: 4px;
    }

    a {
      margin-left: 17px;
    }
  }

  // subtree
  .dc-overview-box__subtree {
    i.dc-icon {
      margin-left: 6px;
      margin-right: 9px;
    }
  }
}
