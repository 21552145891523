p-listbox {
  .p-listbox {
    &.p-focus {
      @apply border-transparent shadow-none;
    }
    &.p-disabled {
      @apply cursor-not-allowed bg-transparent opacity-50;
    }
  }
}

.ui-listbox-tabbed.p-listbox {
  @apply rounded-none border-0 bg-transparent shadow-none;

  .p-listbox-list-container {
    @apply overflow-visible;
  }

  .p-listbox-list:not(.p-disabled) {
    @apply flex flex-row gap-2 rounded-lg p-0;

    .p-listbox-option:not(.p-disabled),
    .p-listbox-option:not(.p-disabled).p-focus {
      @apply rounded-md bg-transparent px-4 py-2 font-medium text-primary-700;

      &.p-listbox-option-selected {
        @apply pointer-events-none bg-white text-black shadow;
      }
    }
  }
}

.ui-listbox-selectbutton.p-listbox {
  @apply rounded-none border-0 bg-transparent shadow-none;

  .p-listbox-list-container {
    @apply overflow-visible;
  }

  .p-listbox-list:not(.p-disabled) {
    @apply flex flex-row gap-2 rounded-lg bg-surface-100 p-1;

    .p-listbox-option:not(.p-disabled),
    .p-listbox-option:not(.p-disabled).p-focus {
      @apply grow justify-center rounded-md bg-transparent px-4 py-2 font-medium text-gray-500;

      &.p-listbox-option-selected {
        @apply pointer-events-none bg-white text-black shadow;
      }
    }
  }
}
