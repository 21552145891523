.p-datatable-filter.p-datatable-popover-filter {
  @apply flex;

  .p-datatable-column-filter-button.p-button-icon-only.p-button-rounded,
  .p-datatable-column-filter-button.p-button-icon-only.p-button-rounded.p-datatable-column-filter-button-open {
    @apply h-auto w-auto bg-transparent p-0 hover:bg-transparent focus:shadow-none;
  }
}

.p-datatable-column-filter-overlay-menu {
  .p-datatable-column-filter-buttonbar {
    @apply gap-3 pt-0;

    &:empty {
      @apply p-0;
    }

    button {
      @apply max-w-[50%] grow rounded-lg bg-primary-500 hover:bg-primary-600;

      &.p-button-outlined {
        @apply border-transparent bg-transparent text-gray-600 hover:border-transparent hover:bg-neutral-100 hover:text-gray-700;
      }

      .p-button-label {
        @apply font-semibold;
      }
    }
  }

  .p-datatable-column-filter-buttons {
    @apply p-4 pt-0;
  }
}
