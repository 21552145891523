.p-divider {
  &.p-divider-horizontal {
    &:before {
      @apply h-px bg-gradient-to-r from-black/5 via-black/5 via-85%;
      border-block-start: 0;
    }

    &.p-divider-dotted:before {
      @apply h-px bg-[length:2px_15px];
      background-image: radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px);
    }

    .p-divider-content {
      @apply flex w-full items-center bg-transparent p-0;

      label {
        @apply text-xxs/4 font-medium text-gray-400;
      }

      aside {
        @apply relative ml-auto pl-24 text-sm/2 font-medium;

        &:before {
          @apply absolute left-0 top-1/2 m-auto flex h-[1px] w-24 bg-gradient-to-r from-black/5 via-50% to-gray-100 content-empty;
        }
      }
    }
  }
}

.p-dialog-content .p-divider {
  .p-divider-content {
    @apply bg-transparent;

    label {
      @apply bg-slate-50 px-2;
    }
  }

  &.p-divider-horizontal {
    &.ml-0 {
      @apply pl-0;

      .p-divider-content {
        @apply pl-0;
      }
    }
  }
  &.p-divider-horizontal:before {
    @apply border-slate-200;
  }
}
