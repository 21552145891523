//
// current badge
//
.badge {
  display: inline-block;
  margin-left: _space(0.25);
  @include _padding(0.125, 0.4);

  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  color: darken($action, 10%);

  background: lighten($action, 37%);

  border-radius: 1em;

  // FR > clean-up
  &.--warning {
    width: auto;
    height: 17px;
    margin: 0;
    padding: 0 4px;

    font-size: 10px;
    text-align: center;
    line-height: 17px;
    color: $white;

    background-color: $warning;
    border-radius: 50px;

    background: $warning;
    text-shadow: 0px 0px 3px $black;
  }
}

a:hover .badge {
  text-decoration: none !important;
}

//
//
// FR > clean-up
// - .n-badge -- kill
// - create only one .badge with various styles (warning, danger, info, inactive)

// badge from Tomáš Sychra
// used in: documents-item.component.html
.n-badge {
  width: auto;
  height: 18px;
  margin: 0 0 0 10px;
  padding: 0 5px;
  display: inline-block;
  position: relative;
  // top:-1px;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgba(25, 25, 25, 0.5);
  border: 1px solid rgba(25, 25, 25, 0.5);
  border-radius: 30px;
}

// Badge in header - Úkoly
ui-header {
  .c-navbar__item {
    position: relative;

    .badge {
      position: relative;
      top: -3px;
      right: -3px;
    }
  }
}
