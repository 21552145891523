// p-message {
//   &.p-message-error {
//     @apply fill-red-600 text-red-700;
//   }
//   &.p-message-warn {
//     @apply fill-orange-600;
//   }
//   &.p-message-success {
//     @apply fill-green-600;
//   }
//   &.p-message-empty {
//     @apply flex items-center justify-center bg-neutral-50 p-4;
//     .p-message-wrapper {
//       @apply block text-center;
//     }
//   }

//   .p-message-wrapper {
//     @apply px-2 py-3.5;
//   }
// }

p-message {
  .p-inline-message {
    @apply justify-start px-2 leading-4;
  }
}
