p-button,
ui-button {
  &:has(.p-button-row-toggler) {
    @apply inline-flex h-6 w-8 items-center justify-center;
  }

  &:has(.p-button-loading-icon) {
    .ui-svg {
      @apply hidden;
    }
  }

  .p-button {
    @apply h-[34px] gap-2 rounded-lg px-4 py-2 font-medium focus-visible:outline-none;

    &.p-button-row-toggler {
      @apply border-none bg-transparent p-0 leading-5 text-gray-600 hover:border-none hover:bg-transparent hover:ring-transparent focus:border-none focus:shadow-none focus:ring-transparent;
    }

    &.p-button-rounded:not(.p-button-text):has(ui-svg) {
      @apply size-10 justify-center rounded-full px-2 py-0 focus:border-primary-300 focus-visible:outline-none;
    }

    &.p-button-rounded.p-button-text:not(:disabled) {
      @apply rounded-full hover:bg-gray-100;
    }

    &:focus {
      @apply shadow-none ring ring-primary-600/20;
    }

    span {
      @apply inline-flex;
    }
  }

  .p-button-icon-only {
    @apply gap-0;

    &.p-button-outlined {
      @apply border border-solid;
    }
    &.p-button-rounded {
      @apply rounded-full;
    }
  }

  &.p-button-rounded {
    .p-button {
      &.p-button-icon-only {
        @apply h-10 w-10 rounded-full;
      }
    }
  }

  &.p-button-info {
    &.p-button-rounded {
      .p-button {
        @apply border-inherit text-inherit;
        &.p-button-icon-only {
          @apply inline-flex items-center justify-center bg-transparent;
        }
      }
    }
  }

  .ui-button-primary {
    @apply rounded-md border-transparent bg-primary-600 font-medium text-white;

    &.p-button:enabled:hover {
      @apply border-transparent bg-primary-700;
    }

    &:active:focus {
      @apply border-transparent bg-primary-600 shadow-none ring ring-primary-600/20;
    }

    &.p-disabled {
      @apply bg-primary-200 opacity-100;
    }
  }

  .ui-button-secondary {
    @apply rounded-lg border-transparent bg-primary-50 font-medium text-primary-700;

    &.p-button:enabled:hover {
      @apply border-transparent bg-blue-light-200 text-primary-700;
    }

    &:focus,
    &:enabled:focus {
      @apply border-primary-300 bg-primary-50 text-primary-700 shadow-none ring ring-primary-600/10;
    }

    &.p-disabled {
      @apply bg-primary-25 text-primary-300 opacity-100;
    }

    &.ui-button-text {
      &.p-button:enabled:hover {
        @apply bg-primary-50 text-primary-700;
      }
    }

    &.ui-button-link {
      &.p-button:enabled:hover {
        @apply text-primary-800;
      }
      &.p-button:disabled {
        @apply text-gray-300;
      }
    }
  }

  .ui-button-tertiary {
    @apply rounded-md border-gray-300 bg-transparent font-medium text-gray-700;

    &:hover,
    &.p-button:enabled:hover {
      @apply border-gray-300 bg-gray-50 text-gray-800;
    }

    &:focus {
      @apply border-gray-300 bg-transparent text-gray-700 shadow-ringGray;
    }

    &.p-disabled {
      @apply border-gray-200 bg-transparent text-gray-300 opacity-100;
    }
  }
  .ui-button-tertiary.ui-button-outlined {
    @apply rounded-lg border-gray-300 bg-transparent font-medium text-gray-500;

    &:hover {
      @apply bg-gray-50 text-gray-800;
    }

    &:focus {
      @apply border-gray-300 bg-gray-50 text-gray-700 shadow-none ring ring-gray-200;
    }
  }

  .ui-button-text {
    @apply whitespace-nowrap border-transparent bg-transparent;

    &.p-button:enabled:hover {
      @apply border-transparent;
    }

    &:active:focus {
      @apply border-transparent bg-transparent shadow-none;
    }

    &.p-disabled {
      @apply border-transparent bg-transparent text-gray-300 opacity-100;
    }
  }

  .ui-button-link {
    @apply h-auto border-transparent bg-transparent p-0 text-primary-600 shadow-none;

    &.p-disabled {
      @apply border-transparent bg-transparent p-0 text-gray-300 shadow-none;
    }

    .p-button-label {
      @apply whitespace-nowrap;
    }
  }

  .ui-button-danger:not(.ui-button-link) {
    @apply bg-red-500 text-red-100;

    &.p-button:enabled:hover {
      @apply bg-red-600 text-red-200;
    }

    &:active:focus {
      @apply shadow-none;
    }

    &.p-disabled {
      @apply bg-red-200 opacity-100;
    }
  }

  .ui-button-danger.ui-button-link {
    @apply text-red-500;
  }

  .ui-button-destructive {
    @apply rounded-md border-transparent bg-red-500 font-medium text-white focus:ring-red-200;

    &.p-button:enabled:hover {
      @apply bg-red-600 text-white;
    }

    &.p-disabled {
      @apply opacity-60;
    }
  }
}
