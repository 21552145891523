.p-selectbutton {
  @apply bg-slate-50;

  &.ng-invalid.ng-dirty {
    @apply outline outline-1 outline-red-300 ring ring-red-100;
  }

  &.p-buttonset {
    .p-button {
      @apply rounded-none first:rounded-l-md last:rounded-r-md;
    }
  }
}
