p-paginator {
  .p-paginator {
    @apply flex justify-start gap-2 rounded-none bg-transparent;

    .p-paginator-prev,
    .p-paginator-next {
      @apply rounded-full p-2 font-medium text-gray-600 focus:ring-0;

      .p-paginator-prev-icon,
      .p-paginator-next-icon {
        @apply inline-flex items-center gap-1;
      }
    }

    .p-paginator-prev {
      @apply pr-4;
    }
    .p-paginator-next {
      @apply pl-4;
    }

    .p-paginator-pages {
      @apply order-1;

      .p-paginator-page {
        @apply rounded-full;

        &.p-paginator-page-selected {
          @apply bg-primary-50 font-medium text-primary-600;
        }
      }
    }
    .p-paginator-rpp-options .p-select-label {
      @apply inline-flex items-center;
    }

    .p-paginator-icon {
      @apply inline-flex items-center gap-1;
    }
  }
}
