ui-button-legacy {
  display: inline-block;
}

//
// Multiple buttons on one row
//
ui-button-legacy + ui-button-legacy,
.dc-btn + .dc-btn {
  margin-left: _space(0.5);
}

.dc-btn {
  @include _btn();
  position: relative;
  @include _padding(0.25, 0.5);
  display: inline-block;

  user-select: none;
  text-decoration: none;
  color: $action;

  border: 1px solid $grey-3;
  border-color: transparent;
  border-radius: 2em;
  background: transparent;

  transition:
    color $speed--normal ease,
    border-color $speed--normal ease,
    background-color $speed--normal ease;

  &:hover:not(.flw__rest-button--disabled__button) {
    color: darken($action, 15%);
  }

  // variants
  &.dc-btn--default {
    color: $text--button;

    &:hover:not(.dc-btn--disabled) {
      color: darken($text--button, 15%);
    }
  }

  &.dc-btn--warning {
    color: $warning;

    &:hover:not(.dc-btn--disabled) {
      color: darken($warning, 15%);
    }
  }

  &.dc-btn--danger {
    color: $danger;

    &:hover:not(.dc-btn--disabled) {
      color: darken($danger, 15%);
    }
  }

  &--faced {
    background-color: transparent;
    border-color: $action;
    color: $action;

    &:hover:not(.dc-btn--disabled) {
      border-color: darken($action, 15%);
    }

    // variants
    &.dc-btn--default {
      border-color: $grey-2;
      color: $text--button;

      &:hover:not(.dc-btn--disabled) {
        border-color: darken($grey-2, 20%);
      }
    }

    &.dc-btn--warning {
      border-color: $warning;
      color: $warning;

      &:hover:not(.dc-btn--disabled) {
        border-color: darken($warning, 15%);
      }
    }

    &.dc-btn--danger {
      border-color: $danger;
      color: $danger;

      &:hover:not(.dc-btn--disabled) {
        border-color: darken($danger, 15%);
      }
    }
  }

  &--full-faced {
    background-color: $action;
    border-color: $action;
    color: $white;

    &:hover:not(.dc-btn--disabled) {
      background-color: darken($action, 15%);
      border-color: darken($action, 15%);
      color: $white;
    }

    &.dc-btn--default {
      background-color: $grey-2;
      border-color: $grey-2;
      color: $text--button;

      &:hover:not(.dc-btn--disabled) {
        background-color: darken($grey-2, 5%);
        border-color: darken($grey-2, 5%);
        color: $text--button;
      }
    }

    &.dc-btn--danger {
      background-color: $danger;
      border-color: $danger;
      color: $white;

      &:hover:not(.dc-btn--disabled) {
        background-color: darken($danger, 5%);
        border-color: darken($danger, 5%);
        color: $white;
      }
    }

    &.dc-btn--warning {
      background-color: $warning;
      border-color: $warning;
      color: $white;

      &:hover:not(.dc-btn--disabled) {
        background-color: darken($warning, 5%);
        border-color: darken($warning, 5%);
        color: $white;
      }
    }
  }

  &--primary {
    background-color: $brand-alt;
    border-color: $brand-alt;
    color: $black;

    &:hover:not(.dc-btn--disabled, .flw__rest-button--disabled__button) {
      background-color: darken($brand-alt, 7%);
      border-color: darken($brand-alt, 7%);
      color: $black;
    }
  }

  &--circled {
    width: 26px;
    height: 26px;
    padding: 0;
    line-height: 26px;
    border-radius: 50%;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
  }
}

//
//  variant: --loading / --submitting
//

//
//  FR > this is new Spinner
//      - for testing - applyied on Login page only
//
button.has-loader:not(.disabled) {
  position: relative;

  // the Spinner
  &:before {
    @include pseudo();
    top: 50%;
    right: _space(0.5);

    margin-top: calc($ui-spinner--size / 2);
    width: $ui-spinner--size;
    height: $ui-spinner--size;

    border: 2px solid rgba($text, 0.4);
    border-left-color: $text;
    border-radius: 50%;

    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;

    // animation: name | duration | timing-function | delay | iteration-count | direction
    animation: animation--rotate 1s linear 0s infinite normal;
  }

  // Spinner container
  &:after {
    content: '';
    display: inline-block;
    height: 100%;

    width: 0px;
    transition-delay: $speed;
    transition-duration: 300ms;
    transition-property: width;
  }

  &.--submitting {
    pointer-events: none;
    cursor: wait;

    // show Spinner
    &:before {
      transition-delay: 300ms;
      transition-duration: 600ms;
      opacity: 1;
      top: 0;
    }

    // make button grow
    // it creates space for the Spinner
    &:after {
      transition-delay: 0s;
      width: calc(#{$ui-spinner--size} + #{$ui-spinner--margin});
    }
  }
}

// FR > all below - clean-up
// FR > all below - clean-up
// FR > all below - clean-up
// FR > all below - clean-up

//
// Pulsating button
//
// FR > rename "btn-effect--pulse"  > "animation--pulse-box-shadow"
.btn-effect--pulse:not(.submitting) {
  animation: animation--pulse-box-shadow 2s infinite;

  &.dc-btn--primary {
    &,
    &:hover {
      border-color: $white;
      color: $brand-alt;
      span {
        color: $text;
      }
    }
  }

  &.dc-btn--danger {
    &,
    &:hover {
      border-color: $white;
      color: $danger;
      span {
        color: $white;
      }
    }
  }
}

//
//  Icon inside <button>
//
.dc-btn.has-icon {
  .fa,
  .dc-icon {
    margin-right: _space(0.125);
  }
}

//
//  Close button
//
.btn {
  &--close {
    display: inline-block;
    width: _space(1);
    height: _space(1);

    font-size: _space(1);
    line-height: _space(1);
    text-align: center;

    cursor: pointer;

    transition: all 100ms ease-in-out;
    &:hover {
      border-radius: 50%;
      text-decoration: none;
      background: $danger;
      color: $white;
    }

    //
    // sizes
    // defauult size = sm
    &.size--sm {
      width: _space(1);
      height: _space(1);
      font-size: _space(1);
      line-height: _space(1);
    }
    &.size--md {
      width: _space(1.25);
      height: _space(1.25);
      font-size: _space(1.25);
      line-height: _space(1.25);
    }
    &.size--lg {
      width: _space(1.5);
      height: _space(1.5);
      font-size: _space(1.5);
      line-height: _space(1.5);
    }
  }
}
