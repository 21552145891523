.p-fileupload.p-fileupload-advanced {
  @apply rounded-lg border-[1px] border-[var(--input-border)] bg-white hover:border-[var(--input-border-hover)];

  .p-fileupload-header {
    @apply justify-between pb-0;
  }

  .p-fileupload-content {
    @apply min-h-fit rounded-b-lg border-none p-0;

    &.p-fileupload-highlight {
      .p-fileupload-placeholder {
        @apply border-neutral-400 bg-neutral-100;
      }
    }

    .p-progressbar {
      @apply hidden;
    }

    &:has(ui-message:first-child) {
      .ui-message.p-message {
        @apply mx-4 mb-3;

        .p-message-wrapper {
          @apply py-1;
        }
        .p-message-icon {
          @apply hidden;
        }
        .p-message-content {
          @apply font-medium;
        }
      }
    }

    .p-fileupload-file-list {
      @apply mx-4 block max-h-[20rem] overflow-y-auto border-0 border-t border-solid border-t-neutral-100 bg-white py-3;

      .p-fileupload-file {
        @apply grid grid-cols-[auto_8rem_4rem] border-t border-neutral-100 first:border-0;

        > div {
          @apply w-fit p-0 pr-2;
        }

        div:first-of-type {
          @apply hidden;
        }
        div:first-of-type > img {
          @apply w-10 max-w-[10rem];
        }

        div:last-of-type {
          @apply flex w-full justify-end;
          .p-button {
            @apply h-8 w-8 rounded-full bg-transparent text-neutral-500 hover:border-none hover:text-neutral-700;
          }
        }

        .p-fileupload-file-name {
          @apply w-auto truncate;
        }
      }
    }

    .p-fileupload-file-list ~ .p-fileupload-placeholder {
      @apply hidden;
    }
  }

  .p-fileupload-placeholder {
    @apply m-4 flex justify-center rounded-lg border-2 border-dashed border-gray-200 bg-neutral-50 p-5;

    ins {
      @apply ml-1;
    }
  }
}

.p-fileupload-hint {
  word-wrap: break-word;
  word-break: break-word;
  @apply mt-3 px-0 text-xs text-neutral-500;
}
