//
//  Border
//
.border {
  border-width: 1px;
  border-style: solid;
  border-color: rgba($black, 0.5);

  &--none {
    border: none !important;
  }
}

//
//  Border width
//
.bw {
  &--1 {
    border-width: 1px;
  }
  &--2 {
    border-width: 2px;
  }
  &--3 {
    border-width: 3px;
  }
  &--4 {
    border-width: 4px;
  }

  &--0 {
    border-width: 0;
  }
}

//
//  Border radius
//
.radius {
  border-radius: $radius--md !important;
  &--sm {
    border-radius: $radius--sm !important;
  }
  &--md {
    border-radius: $radius--md !important;
  }
  &--lg {
    border-radius: $radius--lg !important;
  }
  &--xl {
    border-radius: $radius--xl !important;
  }
  &--circle {
    border-radius: 50% !important;
  }

  &--0 {
    border-radius: 0 !important;
  }
}

//
//  Border color
//
// for border-color visit /utility/colors.scss
