.p-dialog-mask .p-dialog {
  @apply rounded-lg border-none shadow-dialog;
}

.p-dialog {
  &.p-dialog-draggable {
    .p-dialog-header {
      @apply cursor-move;
    }
  }

  .p-dialog-header {
    @apply rounded-t-lg border-0 border-b border-solid border-b-neutral-200 px-6 py-5 text-xl font-semibold text-neutral-600;
    .p-dialog-title {
      @apply font-semibold leading-5;

      span {
        @apply text-neutral-900;
      }
    }

    .p-dialog-header-actions {
      .p-dialog-close-button {
        @apply size-8 p-0 text-neutral-600 enabled:hover:bg-neutral-100;
      }
    }
  }

  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='error']:first-child)
    .p-dialog-header {
    @apply border-b-red-400 bg-red-400 text-white;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='warn']:first-child)
    .p-dialog-header {
    @apply border-b-orange-300 bg-orange-300 text-white;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='info']:first-child)
    .p-dialog-header {
    @apply border-b-blue-100 bg-blue-100 text-primary-800;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='empty']:first-child)
    .p-dialog-header {
    @apply border-b-blue-gray-100 bg-blue-gray-100 text-blue-gray-600;
  }

  .p-dialog-content {
    @apply relative grow bg-slate-50 p-6 text-gray-900;

    .p-dialog-content-container {
      &.loading,
      &.saving {
        @apply relative flex flex-1 flex-col items-center justify-center;

        ui-loader {
          @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
        }
      }
      &.saving {
        @apply block;
      }
      &.ready {
        &:has(p-divider:first-child) {
          @apply -mt-2;
        }

        ui-message[severity='error']:first-child {
          .ui-message.p-message {
            @apply mx-6 -mt-6 mb-7 rounded-t-none;

            .p-message-content {
              @apply whitespace-pre-line pb-3.5;

              .p-message-text {
                @apply text-sm/2.5;
              }
            }
          }
        }
      }

      &.saving + .p-blockui-mask {
        @apply -mt-6 h-[150%] w-[150%] bg-white/85;
      }

      > p-divider:first-child {
        @apply mt-0;
      }

      .p-tabpanels {
        @apply bg-transparent p-6;
      }
      .p-tablist {
        .p-tablist-active-bar {
          @apply h-[2px];
        }
        .p-tablist-content {
          .p-tab {
            @apply w-1/2;

            &.p-tab-active {
              @apply bg-blue-50;
            }

            &:not(.p-disabled) {
              @apply justify-center border-b-[2px] px-6 hover:border-b-gray-500 focus:shadow-none;
            }
          }
        }
      }
    }

    &:has(.p-dialog-content-container.loading),
    &:has(.p-dialog-content-container.saving) {
      @apply flex min-h-40 flex-col items-stretch justify-center overflow-hidden;

      ui-block-ui-container > div {
        ui-loader {
          @apply z-9001;
        }

        .p-blockui {
          --p-blockui-border-radius: 0;
          --p-mask-background: theme(colors.slate.105);
        }
      }
    }
    &:has(dc-platform-ui--flw-form):has(ui-loader) {
      @apply flex;

      .p-dialog-content-container.ready {
        @apply flex flex-1 flex-col items-center justify-center;
      }
    }
    &:has(.p-dialog-content-container.loading.saving) {
      @apply block;
    }
  }

  & &-footer {
    @apply flex justify-between rounded-b-lg border-0 border-t border-solid border-t-neutral-200 px-6 py-4;

    p-checkbox {
      .p-checkbox {
        @apply h-[16px] w-[16px];

        .p-checkbox-box {
          @apply h-full w-full;

          .p-checkbox-icon {
            @apply text-xs;
          }
        }
      }
      .p-checkbox-label {
        @apply font-light text-gray-600;
      }
    }

    .p-button {
      @apply m-0 border-transparent py-2.5 leading-4;

      &-secondary.p-button-outlined {
        @apply border-2 border-transparent text-neutral-600 hover:border-neutral-100 hover:bg-neutral-100;
      }
    }
  }
}

.p-confirm-dialog {
  .p-dialog-footer {
    .p-confirm-dialog-accept {
      @apply bg-primary-500 hover:bg-primary-600;
    }
  }
}
